
import _ from 'lodash';
const HostnameCollection = [
    'https://fxgcp-admin.vinfotechprojects.com',
    'https://predevfx-admin.vinfotechprojects.com',
    'localhost',
    'predevfx-admin.vinfotechprojects.com',
    'fxgcp.vinfotechprojects.com',
    'fxgcp-admin.vinfotechprojects.com'
]

const HostnameCollectionqa = [
    'http://fxqa-admin.vinfotechprojects.com',
    'fxqa-admin.vinfotechprojects.com'
]


const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
console.log(hostname,"hostnamehostname")


const dev = {
    s3: {
        BUCKET: "https:\/\/fantasyexchange-stg.s3.amazonaws.com/",
    },
    apiGateway: {
        URL: 'https://fxgcp.vinfotechprojects.com/'
        // URL: 'https://game.thefantasyxchange.com/'
    }
};

const qa = {
    s3: {
        BUCKET: "https:\/\/fantasyexchange-stg.s3.amazonaws.com/",
    },
    apiGateway: {
        URL: 'http://fxqa.vinfotechprojects.com/'
    }
};

const prod = {
    s3: {
        BUCKET: 'https:\/\/fantasyexchange-stg.s3.amazonaws.com/',
    },
    apiGateway: {
        URL: 'https://game.thefantasyxchange.com/'
    }
};

const config = 
_.includes(HostnameCollection, hostname)  ? dev :
_.includes(HostnameCollectionqa, hostname)  ? qa
    : prod;
console.log(_.includes(HostnameCollection,hostname),"_.includes(HostnameCollection)");
console.log(_.includes(HostnameCollectionqa,hostname),"_.includes(HostnameCollectionqa)");
console.log(config,"config");
export default {
    ...config
};


